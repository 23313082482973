import React, { useState, useEffect } from 'react';

const JollyIntro = () => {
  const [glitchText, setGlitchText] = useState('JOLLY');
  const [offset, setOffset] = useState({ x: 0, y: 0 });
  const [showCorruption, setShowCorruption] = useState(false);
  const [glitchIntensity, setGlitchIntensity] = useState(0);
  const [isVisible, setIsVisible] = useState(false);
  
  useEffect(() => {
    setTimeout(() => setIsVisible(true), 500);

    const glitchInterval = setInterval(() => {
      if (Math.random() > 0.7) {
        const intensity = Math.random();
        setGlitchIntensity(intensity);
        
        // Distorci il testo
        const distortedText = 'JOLLY'.split('')
          .map(char => Math.random() > 0.4 ? char : String.fromCharCode(char.charCodeAt(0) + Math.floor(Math.random() * 12)))
          .join('');
        setGlitchText(distortedText);
        
        // Offset casuale più pronunciato
        setOffset({
          x: (Math.random() * 12 - 6) * intensity,
          y: (Math.random() * 8 - 4) * intensity
        });

        setShowCorruption(Math.random() > 0.8);
        
        setTimeout(() => {
          setGlitchText('JOLLY');
          setOffset({ x: 0, y: 0 });
          setShowCorruption(false);
          setGlitchIntensity(0);
        }, 100);
      }
    }, 50); // Intervallo più breve per glitch più frequenti

    return () => clearInterval(glitchInterval);
  }, []);

  return (
    <div className="App" style={{
      width: '100vw',
      height: '100vh',
      background: '#0a0a0a',
      position: 'relative',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      overflow: 'hidden'
    }}>
      {/* Grid Background */}
      <div style={{
        position: 'absolute',
        inset: 0,
        backgroundImage: 'linear-gradient(90deg, rgba(0, 242, 255, 0.03) 1px, transparent 1px), linear-gradient(rgba(0, 242, 255, 0.03) 1px, transparent 1px)',
        backgroundSize: '50px 50px',
        opacity: 0.2,
        zIndex: 0
      }} />

      {/* Centered Content Container */}
      <div style={{
        position: 'relative',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}>
        {/* Base Glow Layer */}
        <div style={{
          position: 'absolute',
          fontSize: 'clamp(4rem, 15vw, 8rem)',
          fontFamily: 'Orbitron, sans-serif',
          fontWeight: '900',
          color: '#00f2ff',
          opacity: 0.2,
          filter: 'blur(40px)',
          transform: `translate(${offset.x * 1.5}px, ${offset.y * 1.5}px)`,
          zIndex: 1
        }}>
          {glitchText}
        </div>

        {/* Glitch Layers */}
        {Array.from({length: 3}).map((_, i) => (
          <div
            key={`glitch-${i}`}
            style={{
              position: 'absolute',
              fontSize: 'clamp(4rem, 15vw, 8rem)',
              fontFamily: 'Orbitron, sans-serif',
              fontWeight: '900',
              opacity: showCorruption ? 0.5 : 0.3,
              color: i === 0 ? '#ff00e5' : i === 1 ? '#00f2ff' : '#00ff1a',
              transform: `translate(${offset.x * (i - 1)}px, ${offset.y * (i - 1)}px)`,
              filter: `blur(${i * 0.5}px)`,
              mixBlendMode: 'screen',
              zIndex: 2,
              textShadow: `0 0 ${5 + i * 2}px currentColor`
            }}
          >
            {glitchText}
          </div>
        ))}

        {/* Main Text */}
        <div style={{
          position: 'relative',
          fontSize: 'clamp(4rem, 15vw, 8rem)',
          fontFamily: 'Orbitron, sans-serif',
          fontWeight: '900',
          color: 'transparent',
          background: 'linear-gradient(to bottom right, #c4b5fd, #4f46e5)',
          backgroundClip: 'text',
          WebkitBackgroundClip: 'text',
          filter: showCorruption ? 'drop-shadow(0 0 15px rgba(139, 92, 246, 0.8))' : 'drop-shadow(0 0 10px rgba(139, 92, 246, 0.5))',
          transform: `translate(${offset.x}px, ${offset.y}px)`,
          zIndex: 3
        }}>
          {glitchText}
        </div>

        {/* Corruption Effect */}
        {showCorruption && (
          <div style={{
            position: 'absolute',
            inset: '-20%',
            background: 'linear-gradient(45deg, rgba(255,0,229,0.1), rgba(0,242,255,0.1))',
            mixBlendMode: 'overlay',
            animation: 'glitch-corruption 0.2s infinite alternate',
            zIndex: 2
          }} />
        )}

        {/* Scan Lines */}
        <div style={{
          position: 'absolute',
          inset: 0,
          background: 'linear-gradient(transparent 50%, rgba(0, 242, 255, 0.05) 50%)',
          backgroundSize: '100% 4px',
          pointerEvents: 'none',
          zIndex: 4,
          opacity: showCorruption ? 0.3 : 0.1
        }} />

        {/* Ambient Particles */}
        <div style={{
          position: 'absolute',
          inset: '-100vh -100vw',
          width: '300vw',
          height: '300vh',
          zIndex: 0,
          opacity: 0.3,
          pointerEvents: 'none'
        }}>
          {Array.from({ length: 50 }).map((_, i) => (
            <div
              key={i}
              style={{
                position: 'absolute',
                left: `${Math.random() * 100}%`,
                top: `${Math.random() * 100}%`,
                width: '2px',
                height: '2px',
                background: '#00f2ff',
                borderRadius: '50%',
                opacity: Math.random() * 0.5 + 0.3,
                animation: `float-${i} ${Math.random() * 10 + 5}s infinite linear`
              }}
            />
          ))}
        </div>
      </div>

      <style jsx>{`
        @import url('https://fonts.googleapis.com/css2?family=Orbitron:wght@400;700;900&display=swap');

        @keyframes glitch-corruption {
          0% { clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%); }
          100% { clip-path: polygon(2% 2%, 98% 2%, 98% 98%, 2% 98%); }
        }

        ${Array.from({ length: 50 }).map((_, i) => `
          @keyframes float-${i} {
            0% { transform: translate(0, 0); }
            25% { transform: translate(${Math.random() * 100}px, ${Math.random() * 100}px); }
            50% { transform: translate(${Math.random() * -100}px, ${Math.random() * 100}px); }
            75% { transform: translate(${Math.random() * -100}px, ${Math.random() * -100}px); }
            100% { transform: translate(0, 0); }
          }
        `).join('\n')}
      `}</style>
    </div>
  );
};

export default JollyIntro;