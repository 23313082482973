import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import './styles/global.css';
import JollyIntro from './components/JollyIntro';

const App = () => {
  const [loading, setLoading] = useState(true);
  const [showIntro, setShowIntro] = useState(true);
  const [introComplete, setIntroComplete] = useState(false);
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
  const [explosions, setExplosions] = useState([]);

  useEffect(() => {
    const handleLoadComplete = () => {
      setLoading(false);
    };

    if (document.readyState === 'complete') {
      handleLoadComplete();
    } else {
      window.addEventListener('load', handleLoadComplete);
      return () => window.removeEventListener('load', handleLoadComplete);
    }
  }, []);

  useEffect(() => {
    const introTimer = setTimeout(() => {
      setIntroComplete(true);
    }, 2500);

    return () => clearTimeout(introTimer);
  }, []);

  useEffect(() => {
    if (!loading && introComplete) {
      const transitionTimer = setTimeout(() => {
        setShowIntro(false);
      }, 300);
      return () => clearTimeout(transitionTimer);
    }
  }, [loading, introComplete]);

  useEffect(() => {
    const handleMouseMove = (e) => {
      setMousePosition({ x: e.clientX, y: e.clientY });
      if (Math.random() > 0.95) {
        createExplosion(e.clientX, e.clientY);
      }
    };

    window.addEventListener('mousemove', handleMouseMove);
    return () => window.removeEventListener('mousemove', handleMouseMove);
  }, []);

  const createExplosion = (x, y) => {
    const newExplosion = {
      id: Date.now(),
      x,
      y,
      color: ['#00f2ff', '#ff00e5', '#00ff1a'][Math.floor(Math.random() * 3)]
    };
    setExplosions(prev => [...prev, newExplosion]);
    setTimeout(() => {
      setExplosions(prev => prev.filter(exp => exp.id !== newExplosion.id));
    }, 1000);
  };

  const projects = [
    {
      title: "CRIOON",
      description: "Registrazione delle proprie celle frigo",
      tech: ["PHP", "HTML", "JS"],
      color: "#00f2ff",
      link: "https://crioon.example.com"
    },
    {
      title: "YOUR SONG YUOR BEAT",
      description: "Sito per mettere canzoni in coda per eventuali festini",
      tech: ["PHP", "HTML", "JS"],
      color: "#ff00e5",
      link: "https://yoursongyourbeat.jolly.vm.iacca.ml/"
    },
    {
      title: "TEKN0_M1X3R",
      description: "Na specie di console (Working progress)",
      tech: ["REACT", "CSS"],
      color: "#00ff1a",
      link: "https://elektromixlab.jolly.vm.iacca.ml/"
    }
  ];

  const handleProjectClick = (link) => {
    window.open(link, '_blank', 'noopener noreferrer');
  };

  return (
    <div className="cyber-container">
      <AnimatePresence>
        {showIntro ? (
          <motion.div
            className="intro-container"
            exit={{ opacity: 0 }}
            style={{ position: 'fixed', inset: 0, zIndex: 50 }}
          >
            <JollyIntro />
          </motion.div>
        ) : (
          <>
            <div className="cyber-grid"></div>
            <div className="noise-overlay"></div>
            
            <motion.div
              className="cursor-glow"
              animate={{
                x: mousePosition.x - 25,
                y: mousePosition.y - 25
              }}
            />

            {explosions.map(explosion => (
              <motion.div
                key={explosion.id}
                className="explosion"
                initial={{ scale: 0, opacity: 1 }}
                animate={{ scale: 2, opacity: 0 }}
                style={{
                  left: explosion.x,
                  top: explosion.y,
                  backgroundColor: explosion.color
                }}
              />
            ))}

            <div className="content">
              <header className="cyber-header">
                <nav className="cyber-nav container">
                  <motion.div 
                    className="logo"
                    whileHover={{ scale: 1.1 }}
                    onHoverStart={() => createExplosion(100, 50)}
                  >
                    <span className="neon-text">JOLLY</span>
                  </motion.div>
                  
                  <div className="nav-links">
                    {['PROJECTS', 'ABOUT', 'CONTACT'].map((item, i) => (
                      <motion.a
                        key={item}
                        href={`#${item.toLowerCase()}`}
                        className="nav-link"
                        initial={{ x: 50, opacity: 0 }}
                        animate={{ x: 0, opacity: 1 }}
                        transition={{ delay: i * 0.2 }}
                        whileHover={{ scale: 1.1, color: '#00f2ff' }}
                        onHoverStart={() => createExplosion(window.innerWidth - 200 + (i * 100), 50)}
                      >
                        {item}
                        <span className="link-glitch"></span>
                      </motion.a>
                    ))}
                  </div>
                </nav>
              </header>

              <section className="hero-section container">
                <motion.div
                  className="hero-content"
                  initial={{ opacity: 0, y: 50 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: 0.5 }}
                >
                  <h1 className="hero-title">
                    <span className="tech-glitch">WEBSITE</span>
                    <span className="tech-glitch accent">CREATOR</span>
                  </h1>
                  <motion.p
                    className="hero-subtitle"
                    animate={{
                      color: ['#00f2ff', '#ff00e5', '#00ff1a']
                    }}
                    transition={{ duration: 4, repeat: Infinity }}
                  >
                    Programmatore di siti web e molto altro
                  </motion.p>
                </motion.div>
              </section>

              <section id="projects" className="projects-section container">
                <h2 className="section-title tech-text">FEATURED_WORK</h2>
                <div className="projects-grid">
                  {projects.map((project, index) => (
                    <motion.div
                      key={project.title}
                      className="project-card"
                      initial={{ opacity: 0, y: 30 }}
                      animate={{ opacity: 1, y: 0 }}
                      transition={{ delay: index * 0.2 }}
                      whileHover={{ 
                        scale: 1.05,
                        boxShadow: `0 0 20px ${project.color}`,
                      }}
                      whileTap={{ scale: 0.95 }}
                      onClick={() => handleProjectClick(project.link)}
                      style={{ 
                        '--project-color': project.color,
                        cursor: 'pointer',
                      }}
                    >
                      <div className="card-content">
                        <h3 className="card-title neon-text">{project.title}</h3>
                        <p className="card-desc">{project.description}</p>
                        <div className="tech-stack">
                          {project.tech.map(tech => (
                            <span key={tech} className="tech-tag">{tech}</span>
                          ))}
                        </div>
                        <motion.div 
                          className="view-project"
                          initial={{ opacity: 0 }}
                          whileHover={{ opacity: 1 }}
                          style={{
                            position: 'absolute',
                            bottom: '20px',
                            right: '20px',
                            color: project.color,
                            fontSize: '0.9rem',
                            fontWeight: 'bold'
                          }}
                        >
                          VIEW PROJECT →
                        </motion.div>
                      </div>
                      <div className="card-glitch-effect"></div>
                    </motion.div>
                  ))}
                </div>
              </section>

              <section id="contact" className="contact-section container">
                <h2 className="section-title tech-text">CONNECT_WITH_ME</h2>
                <div className="contact-grid">
                  <motion.a
                    href="https://github.com/JollyH3"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="contact-card"
                    initial={{ opacity: 0, y: 30 }}
                    animate={{ opacity: 1, y: 0 }}
                    whileHover={{ 
                      scale: 1.05,
                      boxShadow: '0 0 20px #00f2ff',
                    }}
                    whileTap={{ scale: 0.95 }}
                    style={{ 
                      cursor: 'pointer',
                      backgroundColor: 'rgba(0, 242, 255, 0.1)',
                      padding: '2rem',
                      borderRadius: '8px',
                      border: '1px solid #00f2ff',
                      textDecoration: 'none',
                    }}
                  >
                    <h3 className="card-title neon-text" style={{ color: '#00f2ff' }}>GITHUB</h3>
                    <p className="contact-desc" style={{ color: '#fff', marginTop: '0.5rem' }}>
                      Check out my code and contributions
                    </p>
                    <motion.div 
                      className="view-link"
                      initial={{ opacity: 0.6 }}
                      whileHover={{ opacity: 1 }}
                      style={{
                        marginTop: '1rem',
                        color: '#00f2ff',
                        fontSize: '0.9rem',
                        fontWeight: 'bold'
                      }}
                    >
                      VIEW PROFILE →
                    </motion.div>
                  </motion.a>

                  <motion.a
                    href="mailto:simonegiolai74@gmail.com"
                    className="contact-card"
                    initial={{ opacity: 0, y: 30 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ delay: 0.2 }}
                    whileHover={{ 
                      scale: 1.05,
                      boxShadow: '0 0 20px #ff00e5',
                    }}
                    whileTap={{ scale: 0.95 }}
                    style={{ 
                      cursor: 'pointer',
                      backgroundColor: 'rgba(255, 0, 229, 0.1)',
                      padding: '2rem',
                      borderRadius: '8px',
                      border: '1px solid #ff00e5',
                      textDecoration: 'none',
                    }}
                  >
                    <h3 className="card-title neon-text" style={{ color: '#ff00e5' }}>EMAIL</h3>
                    <p className="contact-desc" style={{ color: '#fff', marginTop: '0.5rem' }}>
                      Get in touch for collaborations
                    </p>
                    <motion.div 
                      className="view-link"
                      initial={{ opacity: 0.6 }}
                      whileHover={{ opacity: 1 }}
                      style={{
                        marginTop: '1rem',
                        color: '#ff00e5',
                        fontSize: '0.9rem',
                        fontWeight: 'bold'
                      }}
                    >
                      SEND MESSAGE →
                    </motion.div>
                  </motion.a>
                </div>
              </section>
            </div>
          </>
        )}
      </AnimatePresence>
    </div>
  );
};

export default App;